

.row {
  margin: 10px;
}


#or {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 20px 0 20px; 
} 

#or span { 
   background:#fff; 
   padding:0 10px; 
}