.LoaderButton .spinning {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}


.Clear {
  background-color: rgba(0,0,0,0);
  color:black;
  border-color:black

}
.Clear:hover {
  background-color: rgba(0,0,0,.1);
  color:black;
  border-color:black
}
.Clear:active {
  background-color: rgba(0,0,0,.1);
  color:black;
  border-color:black
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}