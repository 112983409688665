body {
    background: white;
    background: linear-gradient(to right, white, white);
}

.field {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(0,0,0,0);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    box-shadow: 0px 0px 1px 1px rgb(59, 59, 59);

}

.field:hover {
    background-color: rgba(0,0,0,0);
    box-shadow: 0px 0px 1px 1px #2F97C1;
}

.field.active {
    background-color: rgba(0,0,0,0);
    box-shadow: 0px 0px 1px 1px #2F97C1;
}

.field.active input {
    padding: 24px 16px 8px 16px;
}

.field.active input + label {
    top: 4px;
    opacity: 1;
    color: #000000;
}

.field.locked {
    pointer-events: none;
}

.field input {
    width: 100%;
    height: 56px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
        0.1s padding ease-in-out;
    -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
    color: black;
}
.field input::-moz-placeholder {
    color: black;
}
.field input:-ms-input-placeholder {
    color: black;
}
.field input:-moz-placeholder {
    color: black;
}

.field input + label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: black;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
}

.field input + label.error {
    color: #ec392f;
}

.field p.predicted {
    position: absolute;
    top: 8px;
    left: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: black;
    opacity: 1;
    pointer-events: none;
}
